import { getAcrDrillDowns } from "../../api";

const initialState = {
    isLoading: true
}

export const acr = ({
    state: initialState,
    reducers: {
        setAcrDrillDowns(state, acrResponse) {
            return {
                ...state,
                isLoading: false,
                ...acrResponse
            }
        }
    },
    effects: {
        async loadAcrDrillDowns(params, state) {
            if (!params) return;
            // const { fzCode, brand } = state;
            const acrResponse = await getAcrDrillDowns(Object.entries(params));
            this.setAcrDrillDowns({ ...acrResponse });
        }
    }
});

export default acr;