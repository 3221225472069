import React, { Suspense, lazy } from 'react';
import { Route, Switch, BrowserRouter, Router, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { store } from './rematch/store';
import { history } from './helpers/history';
import './fontStyles.scss';
import config from './config';
import ThemeProvider from './Theme/Provider'
import { AuthProvider } from './Auth'
import UserProvider from './providers/user';
import RedirectModal from './Components/RedirectModal';
import RedirectModalPLK from './Components/RedirectModalPLK';

const Welcome = React.lazy(() => import('./Pages/Welcome'));
const Login = React.lazy(() => import('./Pages/Login'));
const Grading = React.lazy(() => import('./Pages/Grading'));
// const SetUserInfo = React.lazy(() => import('./Pages/Preferences'));
const HowItWorks = React.lazy(() => import('./Pages/HowItWorks'));
const Dashboard = React.lazy(() => import('./Pages/Dashboard'));
const GoogleReview = React.lazy(() => import('./Pages/GoogleReviews'));
const ScoreCard = React.lazy(() => import('./Pages/ScoreCard'));
const Drilldown = React.lazy(() => import('./Pages/Drilldown'));

const Impersonate = React.lazy(() => import('./Pages/Impersonate'));

const PointsDetails = React.lazy(() => import('./Components/points/index'));
const Comments = React.lazy(() => import('./Components/Comments/index'));
const ViewAllRestaurants = React.lazy(() => import('./Pages/ViewAllRestaurants'));
const RowChart = React.lazy(() => import('./Components/row-chart/index'));
const EmployeeTable = React.lazy(() => import('./Components/EmployeeTable/index'));
const Barchart = React.lazy(() => import('./Components/Charts/D3/barChartNew'));
const RowChart1 = React.lazy(() => import('./Components/Charts/D3/rowChart'));

const isDeviceSupported = () => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        console.log('Redirect mobile screen')
        document.write(`
            <div>
                <p style='font-size: 34px; 
                        text-align: center; 
                        left: 0;
                        line-height: 200px;
                        margin-top: -100px;
                        position: absolute;
                        top: 50%;
                        width: 100%;
                        '>
                        Currently Not Supported on Mobile, Multi-Platform Compatibility Coming Soon
                </p>
            </div>`)
    } else {
        console.log('Not mobile, proceed!')
    }
}

const AppWithRouterAccess = () => {
    isDeviceSupported();
    return (
        <React.Fragment>
            <BrowserRouter>
                <Provider store={store}>
                    <AuthProvider>
                        <ThemeProvider>
                            <Switch>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Router history={history}>
                                        <Route exact path='/' component={Welcome} />
                                        <Route path='/impersonate' component={Impersonate} />
                                        <Route path='/grading' component={Grading} />
                                        <Route path='/dashboard' component={Dashboard} />
                                        <Route path='/howitworks' component={HowItWorks} />
                                        <Route path='/scorecard' component={ScoreCard} />
                                        <Route path='/drilldown/:name(gs|bs|tr|at|cc|smg|wt)/:restno/:period?' component={Drilldown} />
                                        <Route path='/pointdetails' component={PointsDetails} />
                                        <Route path="/googlereview/:restno/:period" component={GoogleReview} />
                                        <Route path='/comments' component={Comments} />
                                        <Route path="/viewrestaurants/:user" component={ViewAllRestaurants} />
                                        <Route path="/rowchart" component={RowChart} />
                                        <Route path="/employeetable" component={EmployeeTable} />
                                        <Route path="/Barchart" component={Barchart} />
                                        <Route path="/RowChart1" component={RowChart1} />
                                        <Route path="/implicit/callback">
                                            <Redirect to="/" />
                                        </Route>
                                    </Router>
                                </Suspense>
                            </Switch>
                            <UserProvider>
                                <RedirectModal />
                            </UserProvider>
                            <RedirectModalPLK />                        </ThemeProvider>
                    </AuthProvider>
                </Provider>
            </BrowserRouter>
        </React.Fragment >
    );
};
export default AppWithRouterAccess;