export const modalPLK = ({
    state: {
        isOpen: false,
    },
    reducers: {
        openModal(state) {
            return {
                ...state,
                isOpen: true
            }
        },
        closeModal(state) {
            localStorage.setItem('closedModal', 'true');
            return {
                ...state,
                isOpen: false
            }
        },
    },
});

export default modalPLK;
