import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import {user} from "../reducers/user";
import { useAuthContext } from '../Auth';

const UserProvider = props => {
  const { dispatch, children, userDispatch } = props
    const { authClient } = useAuthContext();
    const user = authClient.authStateManager._authState.idToken?.claims
  useEffect(() => {
    dispatch.user.setUserState(user);
  },[ user ])
  ;
  return (
    <>
    { children }
    </>
  )
};

const mapStateToProps = () => (
  state => ({
    userState: state.user
  }),
  dispatch => ({
    userDispatch: dispatch.user
  })
);

export default connect(mapStateToProps)(UserProvider);