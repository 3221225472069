import { getSmgDeepDive } from "../../api";

const initialState = {
    isLoading: true
}

export const smg = ({
    state: initialState,
    reducers: {
        setSmgDeepDive(state, smgResponse) {
            return {
                ...state,
                isLoading: false,
                ...smgResponse
            }
        }
    },
    effects: {
        async loadSmgDeepDive(params, state) {
            if (!params) return;
            // const { fzCode, brand } = state;
            const smgResponse = await getSmgDeepDive(Object.entries(params));
            this.setSmgDeepDive({ ...smgResponse });
        }
    }
});

export default smg;