const config = {
  oidc: {
    clientId: `${process.env.REACT_APP_BUILD_CLIENT_ID}`,
    issuer: `${process.env.REACT_APP_BUILD_OKTA_ISSUER_URL}`,
    redirectUri: `${window.location.origin}/implicit/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    tokenManager: {
      autoRenew: false,
    },
  },
  api: {
    URL: `https://${process.env.REACT_APP_BUILD_API_ENDPOINT_ID}.execute-api.us-east-1.amazonaws.com`,
    STAGE: `${process.env.REACT_APP_BUILD_API_STAGE}`,
    ENDPOINTS: {
      GET_GRADES: 'get-gradings',
      GET_TABLES: 'get-tables',
      GET_METRICS: 'get-metrics',
      GET_LINECHART: 'get-line-chart',
      GET_RESTVIEW: 'get-rest-view',
      GET_GUEST_SATISFACTION: 'get-guest-satisfaction-acr-drilldown',
      GET_SOS_DEEPDIVE: 'get-sos-deepdive',
      GET_GOOGLE_DEEPDIVE:'get-google-deepdive',
      GET_REV_DEEPDIVE: 'get-rev-deepdive',
      GET_TURNOVER_DEEPDIVE: 'get-turnover-deepdive',
      GET_SMG_DEEPDIVE: 'get-smg-deepdive',
      GET_TRAINING: 'get-training-deepdive',
      GET_CONTACT_CENTER_DEEPDIVE: 'get-cc-deepdive',
      GET_IMPERSONATE_USERS: 'get-impersonate-users'
    }
  }  
}


export default config; 
