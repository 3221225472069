import { getContactCenterDeepDive } from "../../api";

const initialState = {
    isLoading: true
}

export const contactcenter = ({
    state: initialState,
    reducers: {
        setContactCenterDeepDive(state, contactCenterResponse) {
            return {
                ...state,
                isLoading: false,
                ...contactCenterResponse
            }
        }
    },
    effects: {
        async loadContactCenterDeepDive(params, state) {
            if (!params) return;
            const contactCenterResponse = await getContactCenterDeepDive(Object.entries(params));
            this.setContactCenterDeepDive({ ...contactCenterResponse });
        }
    }
});

export default contactcenter;