import { getToolTips } from '../api';

const initialState = { tooltips: []
};

export const tooltip = ({
  state: initialState,
  reducers: {
    setTooltips(state, table) {
      return {
        ...state,
        loading: false,
        tooltips: table
      }
    }
  },
  effects: {
    async loadTooltips(params, state) {
      if (!params) return;
      const
        {  brandCode, period } = params,
        table = await getToolTips(brandCode, period);
        // console.log(table,"checkTool")
      this.setTooltips(table);
    },
    
  }
});

export default tooltip;
