import axios from 'axios';
//import axiosRetry from 'axios-retry';
import config from "../config";

//import internal from "stream";

const {
  GET_GRADES,
  GET_TABLES,
  GET_METRICS,
  GET_LINECHART,
  GET_RESTVIEW,
  GET_GUEST_SATISFACTION,
  GET_SOS_DEEPDIVE,
  GET_GOOGLE_DEEPDIVE,
  GET_REV_DEEPDIVE,
  GET_TURNOVER_DEEPDIVE,
  GET_TRAINING,
  GET_SMG_DEEPDIVE,
  GET_CONTACT_CENTER_DEEPDIVE
} = config.api.ENDPOINTS,
baseUrl = `${ config.api.URL }/${ config.api.STAGE }/api/`
;

const
  requestAPI = (fzCode, brandCode) => ({
    method: 'GET',
    // url: '../assets/apiProxy.json'
    url: `${ baseUrl }${ GET_GRADES }?fzCode=${ fzCode }&countryCode=US&brand=${ brandCode }`
  }),
  // requestFile = {
  //   method: 'GET',
  //   url: '../assets/dashboard2.json'
  // },
  requestStars = entries => {
    const queryString = entries.map(o => o.join('=')).join('&');
    return {
      method: 'GET',
      // url: '../assets/stars.json'
      url: `${ baseUrl }${GET_GRADES}?${ queryString }`
    }
  },
  metricsAPI = entries => {
    const queryString = entries.map(o => o.join('=')).join('&');
    return {
      method: 'GET',
      // url: '../assets/dashboard3.json'
      url: `${ baseUrl }${GET_METRICS}?${ queryString }`
    }
  },
  metricsArlAPI = entries => {
    const queryString = entries.map(o => o.join('=')).join('&');
    return {
      method: 'GET',
        // url: '../assets/dashboard3.json'
        url: `${ baseUrl }${GET_METRICS}?countryCode=US&${ queryString }`
    }
  },
  tableAPI = (period, fzCode, brandCode) => ({
    method: 'GET',
    // url: '../assets/TableData.json'
    url: `${ baseUrl }${ GET_TABLES }?period=${ period }&fzCode=${ fzCode }&countryCode=US&brand=${ brandCode }`
  }),
  tableArlRgmAPI = entries => {
  const queryString = entries.map(o => o.join('=')).join('&');
    return {
      method: 'GET',
      // url: '../assets/arlTableMessages.json'
      url: `${ baseUrl }${GET_TABLES}?countryCode=US&${ queryString }`
    }
  },
  // arlTableFile = () => ({
  //   method: 'GET',
  //   url: '../assets/arlTableMessages.json'
  // }),
  lineChartAPI = entries => {
    const queryString = entries.map(o => o.join('=')).join('&');
    return {
      method: 'GET',
      // url: `${ URL }/${ STAGE }/api/get-line-chart?fzCode=4007990&countryCode=US&restNo=12355&period=Aug 2020&brand=bk`
      url: `${ baseUrl }${ GET_LINECHART }?${queryString}`
    }
  },
  // lineChartStatic = () => ({
  //   method: 'GET',
  //   url: '../assets/lineGraphData.json'
  // }),
  restaurantViewAPI = entries => {
    const queryString = entries.map(o => o.join('=')).join('&');
    return {
      method: 'GET',
      // url: `${ URL }/${ STAGE }/api/${ GET_RESTVIEW }?restNo=24798&period=Jan 2019&countryCode=US&brand=bk`
      url: `${ baseUrl }${ GET_RESTVIEW }?${ queryString }`
    }
  },
  toolTipAPI = (brandCode, period) => ({
    method: "GET",
    url: `${ baseUrl }get-tooltips?countryCode=US&brand=${ brandCode }&period=${ period }`
  }),
  acrDrilldownAPI = entries => {
      const queryString = entries.map(o => o.join('=')).join('&');
      return {
        method: 'GET',
        url: `${ baseUrl }${ GET_GUEST_SATISFACTION }?countryCode=US&${ queryString }`
    }
  },
  trainingDrilldownAPI = entries => {
    const queryString = entries.map(o => o.join('=')).join('&');
    return {
      method: 'GET',
      url: `${ baseUrl }${ GET_TRAINING }?countryCode=US&${ queryString }`
    }
  },
  sosDeepDiveAPI = entries => {
    const queryString = entries.map(o => o.join('=')).join('&');
    return {
        method: 'GET',
        url: `${ baseUrl }${ GET_SOS_DEEPDIVE }?countryCode=US&${ queryString }`
    }
  },
  googleDeepDiveAPI= entries => {
    const queryString = entries.map(o => o.join('=')).join('&');
    return {
      method: "GET",
      url: `${ baseUrl }${GET_GOOGLE_DEEPDIVE}?countryCode=US&${ queryString }`  
    }
  },
  getRevDeepDiveAPI = entries => {
    const queryString = entries.map(o => o.join('=')).join('&');
    return {
      method: 'GET',
      url: `${ baseUrl }${ GET_REV_DEEPDIVE }?countryCode=US&${ queryString }`
    }
  },
  getTurnOverDeepDiveAPI = entries => {
    const queryString = entries.map(o => o.join('=')).join('&');
    return {
      method: 'GET',
      url: `${ baseUrl }${ GET_TURNOVER_DEEPDIVE }?countryCode=US&${ queryString }`
    }
  },
  getContactCenterDeepDiveAPI = entries => {
    const queryString = entries.map(o => o.join('=')).join('&');
    return {
      method: 'GET',
      url: `${ baseUrl }${ GET_CONTACT_CENTER_DEEPDIVE }?countryCode=US&${ queryString }`
    }
  },
  smgDeepDiveAPI = entries => {
    const queryString = entries.map(o => o.join('=')).join('&');
    return {
      method: 'GET',
      url: `${ baseUrl }${ GET_SMG_DEEPDIVE }?countryCode=US&${ queryString }`
    }
  }
  ;

// axiosRetry(axios, {
//   retries: 3, // number of retries
//   retryDelay: (retryCount) => {
//     console.log(`retry attempt: ${retryCount}`);
//     return retryCount * 2000; // time interval between retries
//   },
//   retryCondition: (error) => {
//     // if retry condition is not specified, by default idempotent requests are retried
//     return error.response.status === 503;
//   },
// });

export const getGrades = (fzCode, brandCode) => {
  const url = requestAPI(fzCode, brandCode)
  return axios(url)
    .then( res => {
      const
        { data, status } = res,
        details = data
      ;
      return { details, statusCode: status };
    })
    .catch(err => handleErrors(err, () => ({
      details: { data: [] },
      statusCode: err.response.status,
      statusMessage: (() => {
          if (typeof err.response.data === 'string' ) {
              return err.response.data;
          } else {
              let key, val = err.response.data;
              for (key in val) {
                  return val[key] ? val[key] : val;
              }
          }
      })()
    })));
};

export const getStarsRating = entries => {
  return axios(requestStars(entries))
    .then( res => ({ data: res.data, statusCode: res.status }))
    .then( res => {
      const
          { data, statusCode } = res,
          details = data
      ;
      return { details, statusCode };
    })
    .catch(err => handleErrors(err, () => ({
      details: { data: [] },
      statusCode: err.response.status,
      statusMessage: (() => {
          if (typeof err.response.data === 'string' ) {
              return err.response.data;
          } else {
              let key, val = err.response.data;
              for (key in val) {
                  return val[key] ? val[key] : val;
              }
          }
      })()
    })));
};

// export const getMetricsStatic = () => {
//   return axios(requestFile)
//     .then( res => res.data )
//     .then( data => data.metrics )
// };

export const getMetricsAPI = entries => {
  return axios(metricsAPI(entries))
    .then( res => ({ dataResponse: res.data, statusCode: res.status }))
    .then( metrics => {
      const
          { data, grading, starRating } = metrics.dataResponse.data,
          statusCode = metrics.statusCode
      ;
      return { data, grading, starRating, statusCode };
    })
    .catch(err => handleErrors(err, () => ({
      data: [],
      starRating: '',
      grading: '',
      statusCode: err.response.status,
      statusMessage: (() => {
          if (typeof err.response.data === 'string' ) {
              return err.response.data;
          } else {
              let key, val = err.response.data;
              for (key in val) {
                  return val[key] ? val[key] : val;
              }
          }
      })()
    })));
};

export const getArlMetrics = entries => {
  return axios(metricsArlAPI(entries))
      .then( res => ({ dataResponse: res.data, statusCode: res.status }))
      .then( metrics => {
        const
            { data, grading, starRating } = metrics.dataResponse.data,
            statusCode = metrics.statusCode
        ;
        return { data, grading, starRating, statusCode };
      });
};

export const getRestaurantAPI = (params) => {
  return axios(restaurantViewAPI(params))
    .then( res => ({ dataResponse: res.data, statusCode: res.status }))
    .then( restaurant => {
      const
        { data } = restaurant.dataResponse,
        { statusCode } = restaurant;
        return data.length >= 1 ? { ...data[0], statusCode } : {
          gmname: 'No Restaurant Info',
          restadress: '',
          restno: 'Restaurant Number Is Not Defined',
          statusCode,
          statusMessage: 'No Restaurant Number Returned'
        }
        // restaurant.data[0]
    })
    .catch(err => handleErrors(err, () => ({
      gmname: 'No Restaurant Info',
      restadress: '',
      restno: 'Restaurant Number Is Not Defined',
      statusCode: err.response.status,
      statusMessage: (() => {
          if (typeof err.response.data === 'string' ) {
              return err.response.data;
          } else {
              let key, val = err.response.data;
              for (key in val) {
                  return val[key] ? val[key] : val;
              }
          }
      })()
    })))
};

// export const getLineChartStatic = () => {
//   return axios(lineChartStatic())
//     .then( res => res.data )
// };

export const getTableRows = (period, fzCode, brandCode) => {
  return axios(tableAPI(period, fzCode, brandCode))
    .then(res => ({ ...res.data, statusCode: res.status }))
    .then(table => ({ rows: table.data, statusCode: table.statusCode }))
    .catch(err => handleErrors(err, () => ({
      rows: [],
      statusCode: err.response.status,
      statusMessage: (() => {
          if (typeof err.response.data === 'string' ) {
              return err.response.data;
          } else {
              let key, val = err.response.data;
              for (key in val) {
                  return val[key] ? val[key] : val;
              }
          }
      })()
    })))
};

export const getArlTableRows = (entries, userId) => {
  return axios(tableArlRgmAPI(entries))
    .then(res => ({ ...res.data, statusCode: res.status }))
    .then(table => ({ rows: table.data, statusCode: table.statusCode }))
    .catch(err => handleErrors(err, () => ({
       rows: [],
       statusCode: err.response.status,
       statusMessage: (() => {
           if (typeof err.response.data === 'string' ) {
              return err.response.data;
          } else {
              let key, val = err.response.data;
              for (key in val) {
                  return val[key] ? val[key] : val;
              }
          }
          })()
      })))
};

export const getLineChart = (params) => {
  return axios(lineChartAPI(params))
    .then(res => ({ lineData: res.data, statusCode: res.status }))
    .then(lineChart => ({ line1: lineChart.lineData.data, statusCode: lineChart.statusCode }))
    .catch(err => handleErrors(err, () => ({
      line1: [],
      statusCode: err.response.status,
      statusMessage: (() => {
        if (typeof err.response.data === 'string' ) {
          return err.response.data;
        } else {
          let key, val = err.response.data;
          for (key in val) {
             return val[key] ? val[key] : val;
          }
        }
      })()
    })))
};

export const getToolTips=(brandCode, period)=>{
  return axios(toolTipAPI(brandCode, period))
     .then(res=>res.data)
};

export const getGoogleDeepdive = entries => {
  return axios(googleDeepDiveAPI(entries))
     .then(res=>res.data)
     .catch(err => handleErrors(err, () => ({
      data: [],
      statusCode: err.response.status,
      statusMessage: (() => {
          if (typeof err.response.data === 'string' ) {
              return err.response.data;
          } else {
              let key, val = err.response.data;
              for (key in val) {
                  return val[key] ? val[key] : val;
              }
          }
      })()
  })))
};

export const getAcrDrillDowns = entries => {
  return axios(acrDrilldownAPI(entries))
      .then(res => ({ dataResponse: res.data, statusCode: res.status }))
      .then( acr => ({ ...acr }))
      .catch(err => handleErrors(err, () => ({
          data: [],
          statusCode: err.response.status,
          statusMessage: (() => {
              if (typeof err.response.data === 'string' ) {
                  return err.response.data;
              } else {
                  let key, val = err.response.data;
                  for (key in val) {
                      return val[key] ? val[key] : val;
                  }
              }
          })()
      })))
};

export const getSosDeepDive = entries => {
    return axios(sosDeepDiveAPI(entries))
        .then(res => ({ dataResponse: res.data, statusCode: res.status }))
        .then(sos => ({ ...sos }))
        .catch(err => handleErrors(err, () => ({
            data: [],
            statusCode: err.response.status,
            statusMessage: (() => {
                if (typeof err.response.data === 'string' ) {
                    return err.response.data;
                } else {
                    let key, val = err.response.data;
                    for (key in val) {
                        return val[key] ? val[key] : val;
                    }
                }
            })()
        })))
};

export const getRevDeepDive = entries => {
    return axios(getRevDeepDiveAPI(entries))
        .then(res => ({ dataResponse: res.data, statusCode: res.status }))
        .then(rev => ({ ...rev }))
        .catch(err => handleErrors(err, () => ({
            data: [],
            statusCode: err.response.status,
            statusMessage: (() => {
                if (typeof err.response.data === 'string' ) {
                    return err.response.data;
                } else {
                    let key, val = err.response.data;
                    for (key in val) {
                        return val[key] ? val[key] : val;
                    }
                }
            })()
        })))
};

export const getTurnoverDeepDive = entries => {
    return axios(getTurnOverDeepDiveAPI(entries))
        .then(res => ({ dataResponse: res.data, statusCode: res.status }))
        .then(deep => ({ ...deep }))
        .catch(err => handleErrors(err, () => ({
            data: [],
            statusCode: err.response.status,
            statusMessage: (() => {
                if (typeof err.response.data === 'string' ) {
                    return err.response.data;
                } else {
                    let key, val = err.response.data;
                    for (key in val) {
                        return val[key] ? val[key] : val;
                    }
                }
            })()
        })))
};

export const getContactCenterDeepDive = entries => {
  // console.log('IN API method!!!')
  return axios(getContactCenterDeepDiveAPI(entries))
      .then(res => ({ dataResponse: res.data, statusCode: res.status }))
      .then(contactcenter => ({ ...contactcenter }))
      .catch(err => handleErrors(err, () => ({
          data: [],
          statusCode: err.response.status,
          statusMessage: (() => {
              if (typeof err.response.data === 'string' ) {
                  return err.response.data;
              } else {
                  let key, val = err.response.data;
                  for (key in val) {
                      return val[key] ? val[key] : val;
                  }
              }
          })()
      })))
};

export const getSmgDeepDive = entries => {
    return axios(smgDeepDiveAPI(entries))
        .then(res => ({ dataResponse: res.data, statusCode: res.status }))
        .then(smg => ({ ...smg }))
        .catch(err => handleErrors(err, () => ({
            data: [],
            statusCode: err.response.status,
            statusMessage: (() => {
                if (typeof err.response.data === 'string' ) {
                    return err.response.data;
                } else {
                    let key, val = err.response.data;
                    for (key in val) {
                        return val[key] ? val[key] : val;
                    }
                }
            })()
        })))
}

export const getTrainingDrillDowns = entries => {
  return axios(trainingDrilldownAPI(entries))
      .then(res => ({ dataResponse: res.data, statusCode: res.status }))
      .then( acr => ({ ...acr }))
      .catch(err => handleErrors(err, () => ({
          data: [],
          statusCode: err.response.status,
          statusMessage: (() => {
              if (typeof err.response.data === 'string' ) {
                  return err.response.data;
              } else {
                  let key, val = err.response.data;
                  for (key in val) {
                      return val[key] ? val[key] : val;
                  }
              }
          })()
      })))
};

const handleErrors = (err, callback) => {
    if (err.response) {
        // Request made and server responded
        // console.log(err.response.data);
        // console.log(err.response.status);
        // console.log(err.response.headers);
        return callback();
    } else if (err.request) {
        // The request was made but no response was received
        console.log(err.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', err.message);
    }
}
