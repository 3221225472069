import { getGoogleDeepdive } from '../api';

const initialState = { 
  deepdive: [],
  isLoading: true
};

export const googledeepdive = ({
  state: initialState,
  reducers: {
    setGoogleDeepdive(state, googleReviewsResponse) {
      return {
          ...state,
          isLoading: false,
          deepdive: googleReviewsResponse
      }
    }
  },
  effects: {
    async loadGoogleDeepdive(params, state) {
      if (!params) return;
      // const
      //   {  brandCode, period,userId,fzCode,arl,rgm } = params,
      const googleReviewsResponse = await getGoogleDeepdive(Object.entries(params));
      // console.log('googleReviewsResponse: ', googleReviewsResponse)
      this.setGoogleDeepdive({...googleReviewsResponse});
     
    },
    
  }
});

export default googledeepdive;
