import { getSosDeepDive } from "../../api";

const initialState = {
    isLoading: true
}

export const sos = ({
    state: initialState,
    reducers: {
        setSosDeepDive(state, sosResponse) {
            return {
                ...state,
                isLoading: false,
                ...sosResponse
            }
        }
    },
    effects: {
        async loadSosDeepDive(params, state) {
            if (!params) return;
            const sosResponse = await getSosDeepDive(Object.entries(params));
            // console.log(sosResponse)
            this.setSosDeepDive({ ...sosResponse });
        }
    }
});

export default sos;