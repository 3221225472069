import { getRestaurantAPI } from '../api';
const weights = {
  "X": .63,
  "0": .25,
  "1.0": .25,
  "2.0": .25,
  "3.0": .25,
  "4.0": .25,
  "5.0": .25
};

const initialState = {
  isLoading: true,
  grading: '0',
  // starRating: '0',
  weights: weights,
  fzcode: 0,
  gmname: "",
  restno: "",
  period: "Jan 2019",
  restadress: "",
  sos: "",
  acr: "",
  avgtrainingrate: "",
  turnoverrate: "",
  brandstandards: "",
  sosstarrating: "",
  acrstarrating: "",
  trainingstarrating: "",
  turnoverstarrating: "",
  brandstarrating: "",
  overallstarrating: "0",
  brandstandardsfranchiseaverage: "",
  brandstandardstopperformer: "",
  turnoverfranchiseaverage: "",
  turnovertopperformer: "",
  trainingfranchiseaverage: "",
  trainingtopperformer: "",
  sosfranchiseaverage: "",
  sostopperformer: "",
  acrfranchiseaverage: "",
  acrtopperformer: "",
  timeperiodlist:"no selection,refresh page"
};

export const restaurant = ({
  state: initialState,
  reducers: {
    setRestaurantView(state, restaurantResponse ) {
      return {
        ...state,
        isLoading: false,
        ...restaurantResponse,
        weights
      }
    },
    setRestaurantNumberInput(state, { fzCode, restNo }) {
      return {
        ...state,
        isLoading: false,
        restNo: restNo,
        fzCode: fzCode
      }
    },
    setInitialState(state) {
      return {
        ...initialState,
        isLoading: true,
        weights,
        starRating: state.starRating,
        timeperiodlist: state.timeperiodlist
      }
    },
    setMonthInput(state, params) {
      return {
        ...state,
        isLoading: false,
        ...params
      }
    }
  },
  selectors: {
    definePeriods() {
      return periods => {
        return periods?.map(o => ({ value: o }))
      }
    }
  },
  effects: {
    async loadRestaurantView(params) {
      if(!params || params.brand === null) return;
      const
        { period } = params,
        month = period?.includes('-') ? period.split(' ')[0].concat(' ').concat(period.split(' ')[3]) : period;
        params.period = month;
        //console.log('OBJECT ENTRIES =================> ', Object.entries(params))
        const restaurantObject = await getRestaurantAPI(Object.entries(params))
      ;
      this.setRestaurantView({ ...restaurantObject });
      this.setMonthInput({ month });
    },
    onGradeClick({ month }) {
      this.setMonthInput({ month });
    },
    onChange({ fzCode, restNo }) {
      this.setRestaurantNumberInput({ fzCode, restNo });
    },
    onCardClick({ name, starRating }) {
      this.setMonthInput({ name, starRating });
    },
    async onChangeOfMonth(params) {
      const
        { name, period, brand, restNo, countryCode } = params,
        responseObj = await getRestaurantAPI(Object.entries({ period, brand, restNo, countryCode })),
        starRating = responseObj[name]
      ;
      this.setMonthInput({ month: period, starRating: starRating });
    },
    // async onChangeOfMonthArl(params) {
    //   const
    //     { name, period, brand, restNo, countryCode } = params,
    //     responseObj = await
    // },
    onInitialState() {
      this.setInitialState();
    }
  }
});

export default restaurant;