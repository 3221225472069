import React, { FC, ReactNode, useMemo } from 'react';
import { useAuthContext } from '../Auth';

const BKC = React.lazy(() => import('./BKC'));
const PLK = React.lazy(() => import('./PLK'));

const ThemeSelector: FC<{ children: ReactNode }> = (props) => {
    const { authClient } = useAuthContext();
    const BrandCode = authClient.authStateManager._authState.idToken?.claims?.BrandCode
    const Provider = useMemo(() => BrandCode, [BrandCode]);
    return (
        <>
            <React.Suspense fallback={() => null}>
                {BrandCode === 'BKC' && <BKC />}
                {BrandCode === 'PLK' && <PLK />}
            </React.Suspense>
            <Provider {...props} />
        </>
    )
}
export default ThemeSelector;
