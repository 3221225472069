import { useCallback } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { useAuthContext } from '../../Auth';
import usersToimpersonatePLK from '../../Pages/Impersonate/usersToImpersonatePLK';
import './index.css';
const brokenLinkIcon = require('../../assets/broken_link.svg').default;

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '1101',
    },
    content: {
        position: 'initial',
        width: '834px',
        maxWidth: '680px',
        height: 'auto',
        width: '90vw',
        border: 'none',
        borderRadius: '10px 10px 10px 10px',
        overflow: 'hidden',
        fontfamily: 'FlameSans-Bold',
        borderColor: '#693F34',
        textAlign: 'center',
        padding: '60px 80px',
    }
};

const RedirectModal = props => {
    const
        { authClient } = useAuthContext(),
        { userDispatch } = props;
    // initially userDispatch has data of user logged out
    // after impersonation, the userDispatch is updated with data of the user being impersonated
    let userId = '';
    try {
        userId = authClient.authStateManager._authState.idToken?.claims.UserID.toUpperCase();
    } catch (e) {
        userId = '';
    }
    const { userType, brandCode } = userDispatch;
    const isBKFrancise = userType === 'Franchise' && brandCode.toUpperCase() === "BKC",
        isImpersonatingUser = localStorage.getItem("isImpersonateUser") === "true",
        isInternalImpersonatingBK = isImpersonatingUser && brandCode.toUpperCase() === "BKC";

    let isOpen = isBKFrancise || isInternalImpersonatingBK;

    if (usersToimpersonatePLK.includes(userId)) {
        isOpen = false;
    }
    const logout = useCallback(() => {
        authClient
            .signOut({
                revokeAccessToken: false,
            })
            .then(() => {
                const user = localStorage.getItem("user");
                localStorage.clear();
                localStorage.setItem("user", user);
            })
            .catch((error) => {
                console.log("Error while logging out    :::::  ", error);
            });
    }, [authClient]);

    return (
        <Modal
            isOpen={isOpen}
            style={customStyles}
            contentLabel="rInsights Platform"
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
        >
            <img src={brokenLinkIcon} />

            <h1 className="modal-title">IMPORTANT!</h1>

            <p className="modal-text">
                The <b className="modal-link">rSuccess.rbi.com</b> platform will no longer be accessible after July 25th and will transition to&nbsp;
                <a
                    className="modal-link"
                    href='https://rinsights.com/'
                    target="_blank"
                    rel="noreferrer"
                >
                    www.rinsights.com
                </a>
                &nbsp;starting July 18th.
                Please reach out to your field representative for any questions.
            </p>
            <div className='modal-buttons'>
                <a
                    className="modal-button modal-button-bk"
                    href='https://rinsights.com/'
                    target="_blank"
                    rel="noreferrer"
                >
                    VISIT NEW WEBSITE
                </a>
                <button className="modal-button modal-button-bk" onClick={logout}>LOGOUT</button>
            </div>
        </Modal>
    );

}

const mapStateToProps = (
    dispatch => ({
        userDispatch: dispatch.user,
    })
);

export default connect(mapStateToProps)(RedirectModal);