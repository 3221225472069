import { user } from '../../reducers/user';
import { modalPLK } from '../../reducers/modalPLK';
import { launchDarkly } from './launchDarkly';
import grades from '../../reducers/grades';
import metrics from '../../reducers/metrics';
import table from '../../reducers/table';
import restaurant from '../../reducers/restaurant';
import linechart from '../../reducers/linechart';
import tooltip from '../../reducers/tooltip';
import acr from '../../reducers/drilldowns/acr';
import sos from '../../reducers/drilldowns/sos';
import smg from '../../reducers/drilldowns/smg';
import googledeepdive from "../../reducers/googledeepdive";
import brandstandards from "../../reducers/drilldowns/brandstandards";
import turnover from '../../reducers/drilldowns/turnover';
import training from '../../reducers/drilldowns/training';
import contactcenter from '../../reducers/drilldowns/contactcenter';

export const models = {
    user,
    launchDarkly,
    grades,
    metrics,
    restaurant,
    table,
    linechart,
    tooltip,
    acr,
    sos,
    smg,
    googledeepdive,
    brandstandards,
    turnover,
    training,
    contactcenter,
    modalPLK,
};
