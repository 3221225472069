import { useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import './index.css';
const brokenLinkIcon = require('../../assets/broken_link.svg').default;

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '1101',
    },
    content: {
        position: 'initial',
        width: '834px',
        maxWidth: '680px',
        height: 'auto',
        width: '90vw',
        border: 'none',
        borderRadius: '10px 10px 10px 10px',
        overflow: 'hidden',
        fontfamily: 'FlameSans-Bold',
        borderColor: '#693F34',
        textAlign: 'center',
        padding: '0px',
    }
};

const RedirectModalPLK = props => {
    const { modalDispatch, dispatch, userDispatch } = props,
        { brandCode, userType } = userDispatch;
    
    useEffect(() => {
        if (userType === "Franchise" && brandCode?.toUpperCase() === 'PLK') {
            const closedModal = localStorage.getItem('closedModal');
            if (closedModal !== 'true') dispatch.modalPLK.openModal();
        }
    }, [userType, brandCode]);
    function closeModal() {
        dispatch.modalPLK.closeModal();
    }

    return (
        <Modal
            isOpen={modalDispatch.isOpen}
            style={customStyles}
            contentLabel="rInsights Platform"
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            onRequestClose={closeModal}
        >
            <div className='close-button-container'>
                <button className="close-button" onClick={closeModal} title='Close'>&times;</button>
            </div>
            <div className='modal-content'>
                <img src={brokenLinkIcon} />

                <h1 className="modal-title">IMPORTANT!</h1>

                <p className="modal-text">
                    The <a className="modal-link" href='https://rsuccess.rbi.com/'>Franchise Success Dashboard</a> platform will no longer be accessible after September 30th. Visit the new platform at&nbsp;
                    <a
                        className="modal-link"
                        href='https://rinsights.com/'
                        target="_blank"
                        rel="noreferrer"
                    >
                        www.rinsights.com
                    </a>
                    &nbsp;starting September 1st, and reach out to your field representative for any questions.
                </p>
                <div className='modal-buttons'>
                    <a
                        className="modal-button modal-button-plk"
                        href='https://rinsights.com/'
                        target="_blank"
                        rel="noreferrer"
                    >
                        VISIT NEW WEBSITE
                    </a>
                    <button className="modal-button modal-button-plk" onClick={closeModal}>CONTINUE TO SITE</button>
                </div>
            </div>
        </Modal>
    );

}

const mapStateToProps = (
    dispatch => ({
        userDispatch: dispatch.user,
        modalDispatch: dispatch.modalPLK,
    })
);

export default connect(mapStateToProps)(RedirectModalPLK);