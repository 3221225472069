import { getGrades, getStarsRating } from '../api/';
import { timeParse } from 'd3';

const initialState = {
  data: [{
    grade: "",
    issuedate: "Jul 1st 2021",
    period: 'no selection',
    status: "Grading in progress"
  }],
  isLoading: true,
  period: null
};

const filterByPeriod = data => data.filter((entry) => {
  try {
    const periodSplitted = entry.period.split(" "),
      month = periodSplitted[0],
      year = periodSplitted.pop(),
      period = Date.parse(`${year}-${month}`);
    if (isNaN(period)) return true;
    return period < Date.parse('2022-May');
  } catch (e) {
    console.log(e);
    return true;
  }

});

export const grades = ({
  state: initialState,
  reducers: {
    setGradesList(state, gradesResponse) {
      const { details } = gradesResponse;
      let { data } = details;
      data = filterByPeriod(data);

      return {
        ...state,
        isLoading: false,
        ...gradesResponse,
        data: data?.sort((a,b) => {
          let monthDate = a.period.split('-')[1],
            monthDate2 = b.period.split('-')[1],
            date = new Date(timeParse(monthDate)),
            date2 = new Date(timeParse(monthDate2))
            ;
          return date - date2
        })
      }
    },
    setStarsRatingList(state, starsRatingResponse) {
      const { details } = starsRatingResponse;
      let { data } = details;
      data = filterByPeriod(data);
      return {
        ...state,
        isLoading: false,
        ...starsRatingResponse,
        data: data?.sort((a,b) => {
          let
            date = new Date(timeParse(a.period)),
            date2 = new Date(timeParse(b.period))
            ;
          return date - date2
        })
      }
    },
    setPeriodInput(state, period) {
      return {
        ...state,
        isLoading: false,
        period: period
      }
    },
    setNoResultsUser(state) {
      return {
        ...state,
        isLoading: false,
        period: ""
      }
    },
    setGradeStatus(state, status) {
      return {
        ...state,
        isLoading: false,
        ...status
      }
    }
  },
  selectors: {
    definePeriods () {
      return periods => {
        const { data } = periods;
        return data?.map(o => ({
          value: o.period
        }))
      }
   },
    filterByPeriod() {
      return object => {
        const
            { period, grades } = object,
            filtered = grades?.find(o => o.period === period );
        return filtered;
      }
    }
  },
  effects: {
    async loadGradesList(state) {
      if (!state.brand) return;
      const { fzCode, brand } = state
      const gradesList = await getGrades(fzCode, brand);
      this.setGradesList({ ...gradesList });
    },
    onChange(period) {
      this.setPeriodInput(period)
    },
    onGradeStatus(status) {
      this.setGradeStatus({...status })
    },
    async loadStarsRatingList(params) {
      if (!params.brand) return;
      const starsRatingList = await getStarsRating(Object.entries(params));
      this.setStarsRatingList( { ...starsRatingList });
    },
    noResultsUserState() {
      this.setNoResultsUser({})
    }
  }
});

export default grades;
