import { getMetricsAPI, getArlMetrics } from '../api';
import { metricsNull, staticMetrics, scorecardThresholds } from '../assets/json/dashboard2.json';
import { limits, ticksPositioning, weights } from '../assets/json/limits.json';

const initialState = {
  details: metricsNull,
  staticMetrics: metricsNull,
  isLoading: true,
  grade: '',
  starRating: '',
  weights: weights,
  scorecardThresholds: scorecardThresholds,
  limits: limits,
  ticksPositioning: ticksPositioning
};

export const metrics = ({
  state: initialState,
  reducers: {
    setInitialState() {
      return {
        ...initialState,
        isLoading: true
      }
    },
    setMetrics(state, metricsResponse ) {
      const
        { data, grading } = metricsResponse,
        mergeData = details => {
        const result = Object.values([...details, ...staticMetrics].reduce((a,{header, ...props}) => {
            let { details } = props;
              if(!a[header])
              a[header]  = Object.assign({}, {header,details : [...details]});
            else
              a[header].details.push(...details);
            return a;
          },{}))
          ;
        return result.map(o => {
          return {
            header: o.header,
            details: o.details.reduce((a, {name, ...props}) => {
              const found = a.find(b => b.name === name);
              if(!found) {
                a.push({name: name, ...props});
              }
              else {
                Object.assign(found, {...props});
              }
              return a;
            }, [])
          }
        });
      };
      return {
        ...state,
        ...metricsResponse,
        isLoading: false,
        grade: grading,
        details: mergeData(data)
      }
    },
    setArlMetrics(state, metricsResponse ) {
      const
        { data, grading, starRating } = metricsResponse,
        // { data, grading, starRating, params } = metricsResponse,
        // otherKeys = ['fzCode', 'period', 'brand'],
        // role = Object.keys(params).find(o => !otherKeys.includes(o) ),
        mergeData = details => {
        const result = Object.values([...details, ...staticMetrics].reduce((a,{header, ...props}) => {
              let { details } = props;
              if(!a[header])
                a[header]  = Object.assign({}, {header,details : [...details]});
              else
                a[header].details.push(...details);
              return a;
            },{}))
        ;
        return result.map(o => {
          return {
            header: o.header,
            details: o.details.reduce((a, {name, ...props}) => {
              const found = a.find(b => b.name === name);
              if(!found) {
                a.push({name: name, ...props});
              }
              else {
                Object.assign(found, {...props});
              }
              return a;
            }, [])
          }
        });
      };
      return {
        ...state,
        isLoading: false,
        ...metricsResponse,
        grade: grading,
        starRating: starRating,
        details: mergeData(data)
      }
    },
    setStaticMetrics(state, metricsStatic) {
      return {
        ...state,
        isLoading: false,
        staticMetrics: metricsStatic
      }
    },
    setMetricsNameInput(state, params) {
      const { name, starRating } = params;
      return {
        ...state,
        isLoading: false,
        metricsName: name,
        starRating: starRating
      }
    }
  },
  effects: dispatch => ({
    async loadMetrics(params) {
      if (!params || params.brand === null) return;
      const
        metricsObject = await getMetricsAPI(Object.entries(params))
        //metricsStatic = await getMetricsStatic()
      ;
      this.setMetrics({ ...metricsObject });
      //this.setStaticMetrics(metricsStatic);
    },
    async loadArlMetrics(params) {
      if (!params) return;
      const
          data = await getArlMetrics(Object.entries(params));
      this.setArlMetrics({ ...data, params });
    },
    async onChangeOfPeriod(params) {
      const
        { name, index, period, brand, fzCode, countryCode } = params,
        responseObj = await getMetricsAPI(Object.entries({ period, brand, fzCode, countryCode })),
        found = responseObj ? responseObj['data'][index]['details'].find(o => o.name === name ) : null,
        starRating = found ? found['starRating'] : null
      ;
      dispatch.restaurant.setMonthInput({ starRating });
      dispatch.grades.onChange(period);
    },
    async onChangeOfPeriodArl(params) {
      const
          { name, index, period, brand, userId, fzCode, arl } = params,
          responseObj = await getArlMetrics(Object.entries({ fzCode, arl, brand, period, userId })),
          found = responseObj ? responseObj['data'][index]['details'].find(o => o.name === name ) : null,
          starRating = found ? found['starRating'] : null
      ;
      dispatch.restaurant.setMonthInput({ starRating })
      dispatch.grades.onChange(period);
    },
    onChange(params) {
      this.setMetricsNameInput(params);
    },
    onInitialState() {
      this.setInitialState();
    }
  })
});

export default metrics;
