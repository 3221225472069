import { getRevDeepDive } from "../../api";

const initialState = {
    isLoading: true
}

export const brandstandards = ({
    state: initialState,
    reducers: {
        setBrandStandards(state, brandStandardsResponse) {
            return {
                ...state,
                isLoading: false,
                ...brandStandardsResponse
            }
        }
    },
    effects: {
        async loadBrandStandards(params, state) {
            if (!params) return;
            const brandStandardsResponse = await getRevDeepDive(Object.entries(params));
            this.setBrandStandards(brandStandardsResponse);
        }
    }
});

export default brandstandards;