import { getTableRows, getArlTableRows } from '../api';

const initialState = { rows: [], isLoading: true};

export const table = ({
  state: initialState,
  reducers: {
    setTableRows(state, tableResponse) {
      return {
        ...state,
        isLoading: false,
        tableView: 'Rest_data',
        ...tableResponse
      }
    },
    setArlRgmTableRows(state, tableResponse) {
      const
          { params, rows } = tableResponse,
          otherKeys = ['fzCode', 'period', 'brand'],
          role = Object.keys(params).find(o => !otherKeys.includes(o) )
      ;
      return {
        ...state,
        ...tableResponse,
        isLoading: false,
        rows: rows ? rows.map(o => ({
          fzname: o[`${ role }_nm`],
          restadress: o.rest_address,
          acr: o.acr_per,
          sos: o.sos,
          gmname: o.gmname,
          avgtrainingrate: o.training_ratio,
          turnoverrate: o.emp_turn_over_ratio,
          brandstandards: o.rev_per,
          overallstarrating: o.overall_star_rating,
          prevacr: o.prev_acr_per,
          prevsos: o.prev_sos,
          prevavgtrainingrate: o.prev_training_ratio,
          prevturnoverrate: o.prev_emp_turn_over_ratio,
          prevbrandstandards: o.prev_rev_per,
          prevoverallstarrating: o.prev_overall_star_rating,
          restcnt: o.count_of_rest,
          rest_no: o.rest_no
        })) : []
      }
    },
    setComponentRef(state, height) {
      return {
        ...state,
        height
      }
    },
    setCurrentView(state, view) {
      return {
        ...state,
        isLoading: false,
        ...view
      }
    }
  },
  effects: {
    async loadTableRows(params) {
      if (!params) return;
      const
        // { fzCode } = state.user,
        { period, brand, fzCode } = params,
        table = await getTableRows(period, fzCode, brand);
      this.setTableRows({ ...table });
    },
    async loadArlTableRows(params) {
      if (!params) return;
      const
        data = await getArlTableRows(Object.entries(params));
      this.setArlRgmTableRows({ ...data, params });
    },
    loadComponentRef (myRef) {
      if(myRef.current) {
        this.setComponentRef(myRef.current.offsetHeight);
      }
    },
    onChange(selection) {
     this.setCurrentView({ ...selection });
    },
  }
});

export default table;
