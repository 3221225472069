
export const user = ({
  state: {
    isLoading: true,
    brand: '',
    brandCode: '',
    domain: '',
    franCountry: '',
    franOrgRoles: '',
    franOrganizationId: '',
    restNum: '',
    userId: '',
    userType: '',
    email: '',
    emailVerified: '',
    familyName: '',
    givenName: '',
    locale: '',
    defaultView:""
  },
  reducers: {
    
    setUserState(state, payload) {

      //identifying that logged in user is Admin or not
      if(payload && payload.franAdminLevel && payload.franAdminLevel[0] === "Admin"){
        localStorage.setItem("userIsAdmin", true);
      }

      const switchUser = localStorage.getItem("isImpersonateUser");
      if(switchUser === "true"){
        payload = JSON.parse(localStorage.getItem("impersonatePayload"));
      }

      return {
        ...state,
        isLoading: false,
        brand: payload.Brand,
        brandCode: payload.BrandCode,
        domain: payload.Domain,
        franCountry: payload.FranCountry,
        franOrgRoles: payload.franOrgRoles[0],
        fzCode: payload.franOrganizationId,
        restNum: payload.RestNum,
        userId: payload.UserID,
        userType: payload.UserType,
        rbiUserType: payload.rbiUserType,
        email: payload.email,
        emailVerified: payload.email_verified,
        name: payload.name,
        locale: payload.locale,
        defaultView:payload.defaultView,
        [workflow(payload.franOrgRoles[0])] : payload.name
      };
    }
  },
  effects: dispatch => ({
     async setUser (payload) {
        if (!payload) {
          return;
        }
        dispatch.user.setUserState(payload);
        console.log("payload", payload);
    },
    async setRestaurantNumber ({ fzCode, restno }) {
      return dispatch.restaurant.setRestaurantNumberInput({ fzCode, restNo: restno })
    }
  })
});


const workflow = param => {
  switch (param) {
    case 'Multi-Unit Manager' :
      return 'arl';
    case 'VP of Operations' :
      return 'arl';
    case 'Vice-President Operations' :
      return 'arl';
    case 'Director of Operations' :
      return 'arl';
    case 'Reporting Administrator' :
      return 'fz';
    case 'Reporting Operator' :
      return 'fz';
    case 'Restaurant Manager' :
      return 'rgm';
    case 'Restaurant Manager in Training' :
      return 'rgm';
    default:
      return 'noRoleDefined'
  }
};

