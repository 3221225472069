import { getTrainingDrillDowns } from "../../api";

const initialState = {
    isLoading: true
}

export const training = ({
    state: initialState,
    reducers: {
        setTrainingDrillDowns(state, trainingResponse) {
            return {
                ...state,
                isLoading: false,
                ...trainingResponse
            }
        }
    },
    effects: {
        async loadTrainingDrillDowns(params, state) {
            if (!params) return;
            const trainingResponse = await getTrainingDrillDowns(Object.entries(params));
            this.setTrainingDrillDowns({ ...trainingResponse });
        }
    }
});

export default training;